@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-background text-foreground;
	}
}
